import React from 'react'
import "./Settlement.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import {connect} from 'react-redux'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import { Button, TextField,Autocomplete } from '@mui/material';
import {useForm} from 'react-hook-form'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormGroup from '@mui/material/FormGroup';
import * as FileSaver from 'file-saver';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from "@mui/x-date-pickers";
import SurveyModal from './SurveyModal'

function Settlement(props) {
    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,formState:{errors},register}=useForm()
    const [data,setData]=React.useState([])
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedDate2, setSelectedDate2] = React.useState("");
    const options = ['Option 1', 'Option 2'];
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [allMerchants,setAllMerchants] = React.useState([{name:"First Entry"}])
    const [checked, setChecked] = React.useState(true);
    const [merchantId,setMerchantId] = React.useState("")
    const [error,setError] = React.useState("")

    const [allOrg,setAllOrg] = React.useState([])
    const [selectedOrg,setSelectedOrg] = React.useState(null)

    const [allSurvey,setAllSurvey]=React.useState([])

    const handleChange = (event) => {
        setChecked(event.target.checked);
      };
  

    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/org/all`,{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setAllOrg(arr)
        })
        .catch(err=>{
            console.log(err)
        })

        // axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/survey/all`,{headers:{token:props.user.user}})
        // .then(res=>{
        //     console.log(res)
        //     let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
        //     setAllSurvey(arr)
        // })
        // .catch(err=>{
        //     console.log(err)
        // })
    },[])

    const [open,setOpen] = React.useState(false)
    const [selectedSurvey,setSelectedSurvey]=React.useState(null)
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={5} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>

             {/* <SurveyModal 
             open={open}
             setOpen={setOpen}
             title="Survey Info"
             leftButton="Close"
             options={selectedSurvey?selectedSurvey.options:[]}
             /> */}


            <form className="form">
            <h2 className="mb-3">Export Transaction Dump MTD For an Organization (Excel)</h2>

            <div className="row m-auto">
            <section className="my-2 col-6">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={selectedDate}
                onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />} />
            </LocalizationProvider>
            </section>

            <section className="my-2 col-6">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="End Date"
                inputFormat="dd/MM/yyyy"
                value={selectedDate2}
                onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate2(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />} />
            </LocalizationProvider>
            </section>
            </div>


            <div className="my-3" style={{textAlign:"center"}}>
            <Button onClick={async ()=>{
                const response = await axios({
                url: `${process.env.REACT_APP_DEVELOPMENT}/api/afp/mtd`,
                method: 'POST',
                responseType: 'blob',
                data:{dateGte:selectedDate,dateLte:selectedDate2},
                headers:{token:props.user.user}
                });
                console.log(response)
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, 'settlement.xlsx');

            }} variant="contained">Download Excel</Button>

            </div>
            </form>



            <form className="form">
            <h2 className="mb-3">Export Transaction Dump FTD (Excel)</h2>

<div className="row m-auto">
<section className="my-2 col-12">
<LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
    
    label="Start Date"
    inputFormat="dd/MM/yyyy"
    value={selectedDate}
    onChange={(newValue) => {
        console.log(newValue);
        setSelectedDate(newValue);
    }}
    renderInput={(params) => <TextField fullWidth {...params} />} />
</LocalizationProvider>
</section>


</div>

{error.length>0?<p style={{color:"red",fontWeight:"bold"}}>{error}</p>:null}
<div className="my-3" style={{textAlign:"center"}}>
<Button onClick={async ()=>{
    axios({
    url: `${process.env.REACT_APP_DEVELOPMENT}/api/afp/ftd`,
    method: 'POST',
    responseType: 'blob',
    data:{dateGte:selectedDate},
    headers:{token:props.user.user}
    })
    .then(response=>{
        setError("")
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'settlement.xlsx');
    })
    .catch(err=>{
        setError("Data not found")
    })
    
    

}} variant="contained">Download Excel</Button>

</div>
</form>



{/* <form className="form">
<h2 className="mb-3">Export Transaction Dump For All Stores (Excel)</h2>
<div className="row m-auto">
<section className="my-2 col-6">
<LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
    label="Start Date"
    inputFormat="dd/MM/yyyy"
    value={selectedDate}
    onChange={(newValue) => {
        console.log(newValue);
        setSelectedDate(newValue);
    }}
    renderInput={(params) => <TextField fullWidth {...params} />} />
</LocalizationProvider>
</section>

<section className="my-2 col-6">
<LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
    label="End Date"
    inputFormat="dd/MM/yyyy"
    value={selectedDate2}
    onChange={(newValue) => {
        console.log(newValue);
        setSelectedDate2(newValue);
    }}
    renderInput={(params) => <TextField fullWidth {...params} />} />
</LocalizationProvider>
</section>
</div>
<div className="my-3" style={{textAlign:"center"}}>
<Button onClick={async ()=>{
    const response = await axios({
    url: `${process.env.REACT_APP_DEVELOPMENT}/api/order/settlement`,
    method: 'POST',
    responseType: 'blob',
    data:{date:selectedDate,date1:selectedDate2,type:2},
    headers:{token:props.user.user}
    });
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'settlement.xlsx');

}} variant="contained">Download Excel</Button>
</div>
</form> */}


    {/* <h1>Survey</h1>
    <div style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={allSurvey}
                    columns={columns2}
                    autoPageSize
                    onRowClick={(item,ev)=>{
                        setOpen(true)
                        setSelectedSurvey(item.row)}}
                />
            </div> */}


             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'userName', headerName: 'Merchant Name',valueGetter:(param)=>param.row.userName,width:150},
    { field: 'merchantId', headerName: 'Merchant ID',valueGetter:(param)=>param.row.merchantId,width:150},
    { field: '_id', headerName: 'OrderID',valueGetter:(param)=>param.row.orderId,width:200},
    
    { field: 'mobileNo', headerName: 'Customer Mobile Number',valueGetter:(param)=>param.row.userMobileNo,width:150},
    { field: 'options', headerName: 'Options',valueGetter:(param)=>param.row.options.map(item=>item),width:120},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}

export default connect(mapStateToProps)(Settlement)
