import React from 'react'
import "./Home.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from './Dashhead';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Sector   } from 'recharts';
import axios from 'axios'
import _ from 'lodash'
import { CChart } from '@coreui/react-chartjs';
import {connect} from 'react-redux'
import moment from 'moment';
import QRCode from "react-qr-code";
import { DataGrid } from '@mui/x-data-grid';
import {getUserInfo} from './redux/user/userActions'
import { Button } from '@mui/material';
function Home(props) {
    const [display,setDisplay]=React.useState(false)
    const [dashboardData,setDashboardData]=React.useState({})
    const [allOrderData,setAllOrderData] = React.useState([])

    React.useEffect(()=>{
      //props.getUserInfo(props.user.user)
      axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/afp/${props.user.userInfo.afpId}/orders`,{headers:{token:props.user.user}})
      .then(res=>{
        console.log(res)
        let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
        setAllOrderData(arr)
      })
      .catch(err=>{
        console.log(err)
      })

    },[])

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={1} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>
          
            <h1>Hi, Affiliate</h1>
            {
              props.user.userInfo&&<section>

                <div className="row m-auto justify-content-between">
                  <div className="col-4 info-card shadow">
                <p>Name: <b>{props.user.userInfo.name}</b></p>
                <p>Email: <b>{props.user.userInfo.email}</b></p>
                {/* <p>GroupId: <b>{props.user.userInfo.groupId}</p></b> */}
                <p>Mobile No: <b>{props.user.userInfo.mobileNo}</b></p>
                <p>Id: <b>{props.user.userInfo.afpId}</b></p>
                </div>

                <div className="col-4">
                  <h4>Merchant Onboarding QR</h4>
                  <QRCode
                  size={180}
                  value={`https://cleverpe.com/merchantsignupaf/${props.user.userInfo.afpId}`}
                  viewBox={`0 0 256 256`}
                  />
                  <div>
                  <Button
                  onClick={() => {navigator.clipboard.writeText(`https://cleverpe.com/merchantsignupaf/${props.user.userInfo.afpId}`)}}
                  >Click Copy to clipboard</Button>
                  </div>
                </div>
                </div>

                <div className="orders">
                  <h2>All Orders</h2>
                  <div style={{ height: '50vh', width: '100%' }}>
                <DataGrid
                    rows={allOrderData}
                    columns={columns2}
                    autoPageSize
                />
            </div>
                </div>

              </section>
            }





             </div>
    </div>
    )
}

const mapDispatchToProps = (dispatch)=>{
  return {
    getUserInfo:(token)=>dispatch(getUserInfo(token))
  }
}

const mapStateToProps =({EventUser})=>{
  return {
    user:EventUser
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home)


const columns2 = [
  { field: 'id', headerName: 'ID',width:20},
  //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
  { field: 'orderId', headerName: 'Order Id',valueGetter:(param)=>param.row.orderId,width:150},
  { field: 'status', headerName: 'Status',valueGetter:(param)=>param.row.status,width:150},
  { field: 'total', headerName: 'Total',valueGetter:(param)=>param.row.total,width:150},
  { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.userDetails.name,width:150},
  { field: 'merchantId', headerName: 'MerchantId',valueGetter:(param)=>param.row.userDetails.merchantId,width:150},
  { field: 'customerMobile', headerName: 'Mobile No',valueGetter:(param)=>param.row.userDetails.mobileNo,width:150},
  { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.userDetails.email,width:150},
  { field: 'invoice', headerName: 'Invoice',valueGetter:(param)=>param.row.reciept?"Uploaded":"Pending",width:150},
  {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


];