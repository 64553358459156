import React from 'react'
import Dashhead from '../Dashhead';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios'

import {connect} from 'react-redux'
import {Alert, Button, TextField,Autocomplete } from '@mui/material';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as FileSaver from 'file-saver';

import { LocalizationProvider } from "@mui/x-date-pickers";
import { ContentCopy } from '@mui/icons-material';
import { selectedGridRowsSelector } from '@mui/x-data-grid';

const PreApproval = (props) => {

    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedDate2, setSelectedDate2] = React.useState("");
    const options = ['Option 1', 'Option 2'];
    const [error,setError] = React.useState("")
    const [group,setGroup] = React.useState([]);
    const [display,setDisplay]=React.useState(false)
    const [selectedGroup,setSelectedGroup] = React.useState();

  



    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/afp/${props?.user?.userInfo?.afpId}/org`,{headers:{token:props.user.user}})
        .then(async res=>{
          setGroup(res?.data?.result)
        })
        .catch(err=>{
  
        })
      },[])

      React.useEffect(()=>{
        if(selectedDate && selectedDate2){
          setError("");
        }
      },[selectedDate,selectedDate2]);
    
    
    function handleSelect(e,value){
        setSelectedGroup(value);
    }




  return (
    <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
    <Dashhead id={6} display={display} />
    </div>

    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
    <span className="iconbutton display-mobile">
    <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
    <MenuIcon fontSize="inherit" />
     </IconButton>
     </span>

     {/* <SurveyModal 
     open={open}
     setOpen={setOpen}
     title="Survey Info"
     leftButton="Close"
     options={selectedSurvey?selectedSurvey.options:[]}
     /> */}


    <form className="form">
    <h2 className="mb-3">Pre Approval Report</h2>
    {error && <Alert style={{width:"100%"}} className="alert" severity="error" variant="filled" onClose={()=>{setError(null)}} >{error}</Alert>}

    <div className="row m-auto">
    <section className="my-2 col-6">
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
        label="Start Date"
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        disableFuture={true}
        onChange={(newValue) => {
            
            setSelectedDate(newValue);
        }}
        renderInput={(params) => <TextField fullWidth {...params} />} />
    </LocalizationProvider>
    </section>

    <section className="my-2 col-6">
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
        label="End Date"
        inputFormat="dd/MM/yyyy"
        value={selectedDate2}
        disableFuture={true}
        onChange={(newValue) => {
           // console.log(newValue);
            setSelectedDate2(newValue);
        }
    }
        renderInput={(params) => <TextField fullWidth {...params} />} />
    </LocalizationProvider>
    </section>

    <Autocomplete
          id="autocomplete-demo"
          className="mb-4"
          options={group}
          sx={{width:"100%",marginTop:3}}
          getOptionLabel={(option) => option.name}
          onChange={handleSelect}
          value={selectedGroup}
          renderInput={(params) => (
            <TextField {...params} label="Select a brand" variant="outlined" />
          )}
        />
    </div>


    <div className="my-3" style={{textAlign:"center"}}>
    <Button onClick={async ()=>{
                if(!selectedDate || !selectedDate2){
                  setError("Please select date");
                  return;
                }
                try{
                const response = await axios({
                url: `${process.env.REACT_APP_DEVELOPMENT}/api/preapp/download/afp`,
                method: 'POST',
                responseType: 'blob',
                data:{dateGte:selectedDate,dateLte:selectedDate2,groupId: selectedGroup?.groupId || null},
                headers:{token:props.user.user}
                });
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, 'preApprovalReport.xlsx');
                }
                catch(err){
                  setError("Some Error Occured");
                }

            }} variant="contained"> Download </Button>

    </div>
    </form>
    </div>
    </div>
  )
}


const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}

export default connect(mapStateToProps)(PreApproval)
