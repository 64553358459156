import React from 'react'
import "./Organization.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import {connect} from 'react-redux'
import axios from 'axios'
import { Rectangle } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import QRCode from "react-qr-code";
function OrganizationOrders(props) {
    console.log(props)
    const [display,setDisplay]=React.useState(false)
    const [data,setData]=React.useState([])

    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/afp/${props.user.userInfo.afpId}/org/${props.location.state.groupId}/orders`,{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setData(arr)
        })
        .catch(err=>{
            console.log(err)
        })
    },[])

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={3} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>
             <h1>{props.location.state.name} Organization</h1>
             <h4>Organization's Merchant Onboarding QR</h4>
             <QRCode
                  size={180}
                  value={`https://cleverpe.com/merchantsignupaf/${props.user.userInfo.afpId}?${props.location.state.groupId}`}
                  viewBox={`0 0 256 256`}
            />
            <h1>Organization Orders</h1>

            {data.length>0?<div style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                />
            </div>:<div>No Orders Data</div>}


             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'orderId', headerName: 'Order Id',valueGetter:(param)=>param.row.orderId,width:150},
    { field: 'status', headerName: 'Status',valueGetter:(param)=>param.row.status,width:150},
    { field: 'total', headerName: 'Total',valueGetter:(param)=>param.row.total,width:150},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.userDetails.name,width:150},
    { field: 'merchantId', headerName: 'MerchantId',valueGetter:(param)=>param.row.userDetails.merchantId,width:150},
    { field: 'customerMobile', headerName: 'Mobile No',valueGetter:(param)=>param.row.userDetails.mobileNo,width:150},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.userDetails.email,width:150},
    { field: 'invoice', headerName: 'Invoice',valueGetter:(param)=>param.row.reciept?"Uploaded":"Pending",width:150},
    { field: 'Reciept Date', headerName: 'recieptUploadedAt',valueGetter:(param)=>!param.row.recieptUploadedAt?"Not Available":moment.parseZone(param.row.recieptUploadedAt).local().format("DD/MM/YY"),width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}
  
  
  ];

const mapStateToProps =({EventUser})=>{
    return {
      user:EventUser
    }
  }

export default connect(mapStateToProps)(OrganizationOrders)
