import './App.scss';
import {Switch,Route}  from 'react-router-dom'
import Home from "./components/Home"
import VerifyOtp from './components/Auth/VerifyOtp'
import SignIn from './components/Auth/SignIn'
import Merchants from './components/Merchants/Merchants';
import SignUp from './components/Auth/SignUp';
import Organization from './components/Organization/Organization';
import OrganizationOrders from './components/Organization/OrganizationOrders';
import MerchantInfo from './components/Merchants/MerchantInfo';
import Orders from './components/Orders/Orders';
import OrderInfo from './components/Orders/OrderInfo';
import OEM from './components/OEM/OEM';
import PreApproval from './components/PreApproval/PreApproval';
import OEMOrders from './components/OEM/OEMOrders';
import Settlement from './components/Settlement/Settlement'
//test comment
function App() {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      {/* <Route path="/signup" component={SignUp} /> */}
      <Route path="/dashboard" component={Home} />
      <Route path="/signin" component={SignIn} />
      <Route path="/verifyotp" component={VerifyOtp} />
      <Route path="/merchants" component={Merchants} />
      <Route path="/merchantinfo" component={MerchantInfo} />
      <Route path="/organization" component={Organization} />
      <Route path="/organisationorders" component={OrganizationOrders} />
      <Route path="/oem" component={OEM} />
      <Route path="/oemorders" component={OEMOrders} />
      <Route path="/settlement" component={Settlement} />
      <Route path="/pre-approval" component={PreApproval}/>

    </Switch>
  );
}

export default App;
